import React from "react"

export const Accomplishment = ({
  fadedNumber,
  image,
  title,
  content,
  contentBold,
}) => {
  return (
    <div className="accomplishment-card_component">
      <div className="accomplishment_fadednumber">{fadedNumber}</div>
      <div className="accomplishment_img">{image}</div>
      <div className="accomplishment_title">{title}</div>
      <p className="accomplishment_content">
        {content}{" "}
        <span className="accomplishment_content-bold">{contentBold}</span>
      </p>
    </div>
  )
}

export default Accomplishment
