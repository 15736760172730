import React from "react"
import "./checkwork.style.scss"
import ImageWithText from "../../Homepage/DiscoverPortfolio/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const images = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "About" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`

export const CheckWork = () => {
  const intl = useIntl()
  const { allFile } = useStaticQuery(images)
  const getImageByName = name => {
    let image = null
    image = allFile.edges.find(
      file => file.node.childImageSharp.fluid.originalName === name
    )
    return image
  }

  return (
    <div className="checkwork__container">
      <div className="checkwork__title">
        {intl.formatMessage({
          id: "aboutUs.aboutUsCheckWork.checkwork__title",
        })}
      </div>
      <p className="checkwork__paragraph">
        {intl.formatMessage({
          id: "aboutUs.aboutUsCheckWork.checkwork__paragraph",
        })}
        <span className="checkwork__paragraph-bold">
          {intl.formatMessage({
            id: "aboutUs.aboutUsCheckWork.checkwork__paragraph-bold",
          })}
        </span>
        {intl.formatMessage({
          id: "aboutUs.aboutUsCheckWork.checkwork__paragraph_continuation",
        })}
      </p>
      <div className="checkwork-images__container">
        <ImageWithText
          link="services"
          image={getImageByName("services.webp").node.childImageSharp.fluid}
          text={intl.formatMessage({ id: "Services" })}
          className="image__services"
        ></ImageWithText>
        <ImageWithText
          link="portfolio"
          image={getImageByName("portfolio.webp").node.childImageSharp.fluid}
          text={intl.formatMessage({ id: "Portfolio" })}
          className="image__portfolio"
        ></ImageWithText>
      </div>
    </div>
  )
}

export default CheckWork
