import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./about.module.scss"
import SidebarMenu from "../Sidebar/SidebarMenu"
import Header from "../Header/Header"
import AboutUsText from "./AboutUsText/AboutUsText"
import AboutUsAccomplishments from "./Accomplishments/AboutUsAccomplishments"
import Team from "./AbouUsTeam/Team"
import CheckWork from "./AboutUsCheckWork/CheckWork"
import NextStep from "./AboutUsNextStep/NextStep"
import Footer from "../Homepage/Footer/Footer"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Hero/hero_about_us.webp" }) {
      childImageSharp {
        fluid(maxWidth: 9500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const About = () => {
  const { image } = useStaticQuery(getImage)
  return (
    <div className={styles.aboutUs__container}>
      <SidebarMenu name="ABOUT US"></SidebarMenu>
      <div>
        <Header image={image.childImageSharp.fluid} pageName="ABOUT US" />
        <AboutUsText />
        <AboutUsAccomplishments />
        <Team />
        <CheckWork />
        <NextStep />
        <Footer />
      </div>
    </div>
  )
}

export default About
