import React from "react"
import "./aboutus.style.scss"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const getLogo = graphql`
  {
    logo: file(relativePath: { eq: "logo_transparent@2x.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const AboutUsText = () => {
  const { logo } = useStaticQuery(getLogo)
  const intl = useIntl()
  return (
    <div className="background__container">
      <div className="about-us-text__container">
        <div className="about-us-text__rectangle">
          <Image
            fluid={logo.childImageSharp.fluid}
            alt="Competdac Logo"
            className="about-us-text__image"
          />
          <ul className="about-us-text__rectangle-list">
            <li className="list__font">
              <span>
                {" "}
                {intl.formatMessage({
                  id: "aboutUs.aboutUsText.quality1",
                })}{" "}
              </span>
            </li>
            <li className="list__font">
              <span>
                {" "}
                {intl.formatMessage({
                  id: "aboutUs.aboutUsText.quality2",
                })}{" "}
              </span>
            </li>
            <li className="list__font">
              <span>
                {" "}
                {intl.formatMessage({
                  id: "aboutUs.aboutUsText.quality3",
                })}{" "}
              </span>
            </li>
            <li className="list__font">
              <span>
                {" "}
                {intl.formatMessage({
                  id: "aboutUs.aboutUsText.quality4",
                })}{" "}
              </span>
            </li>
          </ul>
        </div>
        <h1 className="about-us-text__text">
          {intl.formatMessage({
            id: "aboutUs.aboutUsText.about-us-text__text",
          })}
        </h1>
      </div>
    </div>
  )
}

export default AboutUsText
