import React from "react"
import "./team.style.scss"
import TeamMember from "./TeamMember"
import { teamMembers } from "../../../utilities/constants"
import { useIntl } from "gatsby-plugin-intl"

const Team = () => {
  const intl = useIntl()

  return (
    <div className="team__container">
      <div className="team__title">
        {intl.formatMessage({ id: "aboutUs.aboutUsTeam.team__title" })}
      </div>
      <p className="team__paragraph">
        {" "}
        {intl.formatMessage({ id: "aboutUs.aboutUsTeam.team__paragraph" })}
      </p>
      <div className="team__members-container">
        {teamMembers.map((member, i) => {
          return (
            <TeamMember
              key={i}
              name={member.name}
              position={member.position}
              phone={member.phone}
              mail={member.mail}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Team
