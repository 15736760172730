import React from "react"
import "./accomplishments.style.scss"
import Accomplishment from "./Accomplishment"
import { benefitsConst } from "../../../utilities/constants"
import Group from "../../../images/Home/group.svg"
import Agreement from "../../../images/Home/agreement.svg"
import Delivery from "../../../images/Home/delivery.svg"
import Money from "../../../images/Home/money.svg"
import { useIntl } from "gatsby-plugin-intl"

export const AboutUsAccomplishments = () => {
  const intl = useIntl()
  return (
    <div>
      <div className="accomplishments__title">
        {intl.formatMessage({
          id: "aboutUs.accomplishments.accomplishments__title",
        })}
      </div>
      <div className="accomplishments__container">
        <div className="accomplishment__logos">
          {benefitsConst.map((accomplish, i) => {
            return (
              <Accomplishment
                key={i}
                fadedNumber={accomplish.fadedNumber}
                image={
                  <img
                    src={
                      i === 0
                        ? [Group]
                        : i === 1
                        ? [Agreement]
                        : i === 2
                        ? [Delivery]
                        : [Money]
                    }
                    alt="accomplishment-img"
                    className="accomplishment__image"
                  />
                }
                title={accomplish.title}
                content={intl.formatMessage({
                  id: "aboutUs.accomplishments." + accomplish.content,
                })}
                contentBold={
                  accomplish.contentBold.length !== 0 &&
                  intl.formatMessage({
                    id: "aboutUs.accomplishments." + accomplish.contentBold,
                  })
                }
              />
            )
          })}
        </div>
        <div className="vertical__line"></div>
        <div className="horizontal__line"></div>
      </div>
    </div>
  )
}

export default AboutUsAccomplishments
