import React from "react"
import "./nextstep.style.scss"
import { useIntl, Link } from "gatsby-plugin-intl"

const NextStep = () => {
  const intl = useIntl()

  return (
    <div className="next-step__container">
      <div className="next-step__title">
        {intl.formatMessage({ id: "aboutUs.aboutUsNextStep.next-step__title" })}
      </div>
      <p className="next-step__paragraph">
        {intl.formatMessage({
          id: "aboutUs.aboutUsNextStep.next-step__paragraph",
        })}
      </p>
      <Link to="/contact" className="next-step__button">
        {intl.formatMessage({
          id: "aboutUs.aboutUsNextStep.next-step__button",
        })}
      </Link>
    </div>
  )
}

export default NextStep
